
import {Component, Vue} from 'vue-property-decorator'
import KionTemplate from '@/components/Shared/Template.vue'
import Template from '@/components/Shared/Template.vue'
import {Subscription} from '@/util/promo'
import KionCard from '@/components/Shared/Cards/Card.vue'
import KionSmallCard from '@/components/Shared/Cards/SmallCard.vue'
import {mainService} from '@/util/mainService'
import KionModal from '@/components/Shared/modal/Modal.vue'
import KionPaymentInit from '@/views/main/components/modals/PaymentInit.vue'
import KionPaymentConfirm from '@/views/main/components/modals/PaymentConfirm.vue'
import KionButton from '@/components/Shared/Button.vue'
import KionInput from '@/components/Shared/Input.vue'
import { httpService } from '@/util/HttpAdapter'

export enum PaymentStep {
  Init,
  Phone,
  Card,
  Error,
  Success
}


@Component({
  components: {
    Template,
    KionTemplate,
    KionCard,
    KionSmallCard,
    KionModal,
    KionPaymentInit,
    KionPaymentConfirm,
    KionButton,
    KionInput
  }
})
export default class KionSubscriptions extends Vue {
  loading = false
  selectedSubscription: Subscription | null = null
  bigPictures: Subscription[] = []
  smallPictures: Subscription[] = []
  code = ''
  step = PaymentStep.Init
  PaymentStep = PaymentStep

  closeModal() {
    this.selectedSubscription = null
    if (this.code.toLowerCase() !== 'kionmir') {
      this.step = 0
    }
  }

  isSelectedPaymentMethod() {
    return [PaymentStep.Phone, PaymentStep.Card].indexOf(this.step) !== -1
  }

  async mounted() {
	this.selectedSubscription = httpService.selectedSubscription
  }

  destroyed() {
    mainService.isFooterVisible.next(true)
  }
}
